import React, { useEffect } from "react";
import io from "socket.io-client";
import { Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import history from "./history";

import { setInprogressJobs } from "./actions/jobs";

import LoginForm from "./views/LoginForm";
import UserList from "./views/UserList";
import MyJobs from "./views/MyJobs";
import Workers from "./views/Workers";

import CustomSidebar from "./components/CustomSidebar";
import TopNav from "./components/TopNav";
import Toasts from "./components/Toasts";
import LoadingView from "./components/LoadingView";

import styles from "./app.module.scss";
import MyStats from "./views/MyStats";

const ENDPOINT = "https://transcoder-api-skt.meshstream.io";
const initSocket = (token) => {
  const socket = io(ENDPOINT, {
    query: {
      jwtToken: token,
    },
  });

  return socket;
};

const App = () => {
  const { auth, toasts, loading } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth) {
      const socket = initSocket(auth.token);
      socket.on("connect", () => {
        console.log("連接!");
      });
      socket.on("job-progress", (data) => {
        // admin 會收到自己 & 一般 user 的 jobs; 一般 user 只會收到自己正在進行的 jobs
        dispatch(setInprogressJobs(data));
      });
    }
  }, [auth]);

  return (
    <div className={styles.app}>
      {toasts.map((toast, index) => (
        <Toasts toast={toast} key={index} />
      ))}

      {auth.token ? <TopNav /> : null}
      {auth.token ? <CustomSidebar /> : null}
      <div className={styles.main}>
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => (auth.token ? <MyJobs /> : <LoginForm />)}
            />
            <Route
              path="/workers"
              render={() => (auth.token ? <Workers /> : <LoginForm />)}
            />
            <Route
              path="/users"
              render={() => (auth.token ? <UserList /> : <LoginForm />)}
            />
            <Route
              path="/analytics"
              render={() => (auth.token ? <MyStats /> : <LoginForm />)}
            />
          </Switch>
        </Router>
      </div>
      {loading.isLoading ? <LoadingView /> : null}
    </div>
  );
};

export default App;
